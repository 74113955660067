import { LOGIN, LOGOUT } from "../ActionList"

const defaultState = {
  token: null,
  urlPrefix: '/'
}

function Authentication(state = defaultState, action) {
  switch (action.type) {
    case LOGIN:
      const { token, urlPrefix } = action.payload
      
      localStorage.setItem('token', token)
      localStorage.setItem('urlPrefix', urlPrefix)

      return {
        token: token,
        urlPrefix: urlPrefix
      }

    case LOGOUT:
      localStorage.clear()

      return {
        token: null,
        urlPrefix: '/'
      }

    default:
      return state
  }
}

export default Authentication
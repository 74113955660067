import { LOGIN, LOGOUT } from "./ActionList"

export const Login = (token, urlPrefix) => ({
  type: LOGIN,
  payload: {
    token: token,
    urlPrefix: urlPrefix
  }
})

export const Logout = () => ({
  type: LOGOUT
})

export const mapStateToProps = state => ({
  token: state.Authentication.token,
  urlPrefix: state.Authentication.urlPrefix
})

export const mapDispatchToProps = {
  Login,
  Logout
}
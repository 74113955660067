import { lazy, Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { mapDispatchToProps, mapStateToProps } from './redux/Action'

const MainPage = lazy(() => import('./components/Main'))
const AuthPage = lazy(() => import('./components/Auth'))
const AdminPage = lazy(() => import('./components/Admin'))

function App(props) {
  const { Login, token, urlPrefix } = props
  const [isDataLoaded, setDataLoadState] = useState(false)
  
  useEffect(() => {
    if (token === null && urlPrefix === '/') {
      const savedToken = localStorage.token
      const savedUrlPrefix = localStorage.urlPrefix

      if (savedToken !== null && savedUrlPrefix) {
        Login(savedToken, savedUrlPrefix)
      }

      setDataLoadState(true)
    }
  }, [token, urlPrefix, Login])

  const Loading = () => (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <progress className="progress is-primary" max={100}>100</progress>
      </div>
    </div>
  )

  if (!isDataLoaded) {
    return (
      <Loading />
    )
  }

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/admin">
            <AdminPage />
          </Route>
          <Route path="/auth">
            <AuthPage />
          </Route>
          <Route path="/">
            <MainPage />
          </Route>
        </Switch>
      </Suspense>
    </Router>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
